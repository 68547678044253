import React, { useEffect, useRef } from "react";
import { RouteComponentProps, StaticContext } from "react-router";
import { Box, Typography } from "@material-ui/core";
import {
  BannerSeverity,
  Icon,
  IconName,
  MyTripsMobileCard,
  NotificationBanner,
} from "halifax";
import clsx from "clsx";
import {
  HotelItinerary,
  HotelItineraryState,
  LodgingCollectionEnum,
  MyTripsFilter,
} from "redmond";

import { HotelCardConnectorProps } from "./container";
import * as textConstants from "./constants";
import { DesktopHotelCard } from "./components/DesktopHotelCard";
import { scrollTopWithOffset } from "../../component";

import "./styles.scss";
import { PersonalBusinessLabel } from "../../../capone-corporate/PersonalBusinessLabel";

export const getStatusBanner = (status?: HotelItineraryState) => {
  switch (status) {
    case HotelItineraryState.Canceled:
    case HotelItineraryState.CancellationFailure:
    case HotelItineraryState.Cancelled:
      return (
        <NotificationBanner
          className="status-banner"
          label={textConstants.CANCELED_RES}
          severity={BannerSeverity.ERROR}
          icon={<Icon name={IconName.ErrorAlert} />}
        />
      );
    default:
      return undefined;
  }
};

export interface IHotelCardProps
  extends HotelCardConnectorProps,
    RouteComponentProps<{}, StaticContext, { prevPath?: string }> {
  isMobile?: boolean;
  hotel: HotelItinerary;
  expandedCard: string;
  onExpandCard: (cardId: string) => void;
  isCorporate?: boolean;
}

export const HotelCard = ({
  history,
  hotel,
  isMobile,
  populateTripQueryParams,
  setSelectedHotel,
  onExpandCard,
  expandedCard,
  setOpenModal,
  isRapidRebookRenameEnabled,
  isCorporate,
  tripsFilter,
}: IHotelCardProps) => {
  const isPastTrips = tripsFilter === MyTripsFilter.PAST_TRIPS;
  const hotelRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (
      !isMobile &&
      hotelRef?.current &&
      expandedCard === hotel.reservation.reservationId
    ) {
      scrollTopWithOffset(hotelRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expandedCard]);

  const { ReservationState: reservationState } = hotel.reservation.state;
  const statusBanner = getStatusBanner(reservationState);
  const isCancel = [
    HotelItineraryState.Canceled,
    HotelItineraryState.CancellationFailure,
    HotelItineraryState.Cancelled,
  ].includes(reservationState);
  const confirmationCodeClassName = isCancel ? "warning" : "";

  const travelerNames = hotel.reservation.guests.map(
    (person) => `${person?.givenName} ${person?.surname}`
  );

  return (
    <Box className={clsx({ mobile: isMobile }, "hotel-list")}>
      {isMobile ? (
        <MyTripsMobileCard
          onClick={() => {
            setSelectedHotel(hotel);
            populateTripQueryParams(history);
          }}
          banner={statusBanner}
          confirmationCodeClassName={confirmationCodeClassName}
          confirmationLabel={textConstants.CONFIRMATION}
          label={
            hotel.reservation.lodgingCollection ===
            LodgingCollectionEnum.Premier
              ? textConstants.PREMIER_COLLECTION_HEADER_TEXT
              : hotel.reservation.lodgingCollection ===
                LodgingCollectionEnum.Lifestyle
              ? textConstants.LIFESYLE_COLLECTION_HEADER_TEXT
              : textConstants.HOTEL_HEADER_TEXT
          }
          icon={IconName.HotelFunnelIcon}
          personalBusinessLabel={
            <PersonalBusinessLabel isCorporate={isCorporate} />
          }
          travelerNames={travelerNames}
          {...textConstants.getHotelInfoDetails(hotel)}
        />
      ) : (
        <div ref={hotelRef}>
          <Box className="hotel-card-icon-title">
            <Icon name={IconName.HotelFunnelIcon} />
            <Typography variant="body1" className="summary-label">
              {textConstants.getHeaderText(
                hotel.reservation.checkInDate,
                hotel.reservation.checkOutDate,
                hotel.reservation.lodgingCollection ===
                  LodgingCollectionEnum.Premier,
                hotel.reservation.lodgingCollection ===
                  LodgingCollectionEnum.Lifestyle
              )}
              <PersonalBusinessLabel isCorporate={isCorporate} />
            </Typography>
          </Box>
          <DesktopHotelCard
            isRapidRebookRenameEnabled={isRapidRebookRenameEnabled}
            banner={statusBanner}
            isPastTrips={isPastTrips}
            hotel={hotel}
            isCancel={isCancel}
            confirmationCodeClassName={confirmationCodeClassName}
            isMobile={isMobile}
            expandedCard={expandedCard}
            onExpandCard={onExpandCard}
            setOpenModal={setOpenModal}
            travelerNames={travelerNames}
            tripsFilter={tripsFilter}
          />
        </div>
      )}
    </Box>
  );
};
