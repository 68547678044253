import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../../../reducers/types";
import { MobileHotelItineraryDetails } from "./component";
import { getSelectedHotel, getTripsFilter } from "../../../../reducer";
import { setSelectedHotel, setOpenModal } from "../../../../actions/actions";
import { withRouter } from "react-router";
import { isRapidRebookRenameEnabledSelector } from "../../../../../DisruptionProtection/reducer";

const mapStateToProps = (state: IStoreState) => ({
  hotel: getSelectedHotel(state),
  tripsFilter: getTripsFilter(state),
  isRapidRebookRenameEnabled: isRapidRebookRenameEnabledSelector(state),
});

const mapDispatchToProps = {
  setOpenModal,
  setSelectedHotel,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MobileHotelItineraryDetailsConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedMobileHotelItineraryDetails = connector(
  withRouter(MobileHotelItineraryDetails)
);
