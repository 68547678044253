import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import { IStoreState } from "../../../../../../reducers/types";
import { MobileFlightItineraryDetails } from "./component";
import {
  getFetchFlightDisruptionsCallState,
  getFlightDisruptions,
  isDisruptionProtection24hRuleSatisfiedSelector,
  hasUpcomingReturnFlightInBookedItineraryAfterRebookSelector,
  isRapidRebookRenameEnabledSelector,
  isDisruptionProtection24hRuleEnabledSelector,
  getMcpViitineraries,
} from "../../../../../DisruptionProtection/reducer/selectors";
import { setSelectedFlightDisruptionProtectionItineraryId } from "../../../../../DisruptionProtection/actions/actions";
import {
  getAirlinesMap,
  getAirportMap,
  getPaymentMethods,
  getSelectedFlight,
  getTripsFilter,
  upcomingItinerariesSelector,
} from "../../../../reducer";
import { setSelectedFlight, setOpenModal } from "../../../../actions/actions";

const mapStateToProps = (state: IStoreState) => ({
  flight: getSelectedFlight(state),
  mcpViItineraries: getMcpViitineraries(state),
  airportMap: getAirportMap(state),
  airlineMap: getAirlinesMap(state),
  tripsFilter: getTripsFilter(state),
  fetchFlightDisruptionsCallState: getFetchFlightDisruptionsCallState(state),
  isDisruptionProtection24hRuleEnabled:
    isDisruptionProtection24hRuleEnabledSelector(state),
  flightDisruptions: getFlightDisruptions(state),
  upcomingItineraries: upcomingItinerariesSelector(state),
  isDisruptionProtection24hRuleSatisfied:
    isDisruptionProtection24hRuleSatisfiedSelector(state),
  hasUpcomingReturnFlightInBookedItineraryAfterRebook:
    hasUpcomingReturnFlightInBookedItineraryAfterRebookSelector(state),
  isRapidRebookRenameEnabled: isRapidRebookRenameEnabledSelector(state),
  paymentMethods: getPaymentMethods(state),
});

const mapDispatchToProps = {
  setSelectedFlight,
  setOpenModal,
  setSelectedFlightDisruptionProtectionItineraryId,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MobileFlightItineraryDetailsConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedMobileFlightItineraryDetails = connector(
  withRouter(MobileFlightItineraryDetails)
);
