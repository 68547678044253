import { isRapidRebookRenameEnabledSelector } from "./../../../../../DisruptionProtection/reducer/selectors/index";
import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../../../reducers/types";
import {
  setOpenModal,
  setSelectedFlight,
  setSelectedHotel,
} from "../../../../actions/actions";
import { ItinerariesModal } from "./component";
import {
  getAirportMap,
  getOpenModal,
  getTripsFilter,
} from "../../../../reducer";

const mapStateToProps = (state: IStoreState) => ({
  openModal: getOpenModal(state),
  isRapidRebookRenameEnabled: isRapidRebookRenameEnabledSelector(state),
  tripsFilter: getTripsFilter(state),
  airportMap: getAirportMap(state),
});

const mapDispatchToProps = {
  setOpenModal,
  setSelectedHotel,
  setSelectedFlight,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type ItinerariesModalConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedItinerariesModal = connector(ItinerariesModal);
