import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import {
  ActionLink,
  ActionLinks,
  IActionLink,
  Icon,
  IconName,
  MyTripsCard,
  TripInfoDetails,
  VacationRentalOccupancyInfo,
  useDeviceTypes,
} from "halifax";
import React from "react";
import {
  HomesItinerary,
  HomesReservation,
  IOpenModal,
  ItineraryEnum,
  ItineraryWithType,
  ListingCollectionEnum,
  MyTripsModalTypes,
} from "redmond";
import "./styles.scss";

import {
  DESKTOP_OFFSET_SCROLL,
  MOBILE_OFFSET_SCROLL,
} from "../../../../constants";
import * as textConstants from "../../constants";
import { DesktopHomeCardConnectorProps } from "./container";
import { HomeMap } from "../HomeMap";
import { SummaryCard } from "../../../SummaryCard";
import {
  HomeCancellationPolicy,
  HomeCheckInCheckOutPolicy,
} from "../HomeInfoParts/component";
import { TravelersSummary } from "../../../TravelersSummary";
import { ConfirmationSummary } from "../../../ConfirmationSummary";
import { PaymentSummary } from "../../../PaymentSummary";
import {
  calculateHomeTotals,
  getCurrencyString,
} from "../../../../../../../../utils/helpers";
import { getLabels, getValues } from "../../../FlightCard/helpers";
interface IDesktopHomeCardProps extends DesktopHomeCardConnectorProps {
  expandedCard: string;
  home: HomesItinerary;
  isPastTrips?: boolean;
  banner?: JSX.Element;
  confirmationCodeClassName?: string;
  isCancel?: boolean;
  isMobile?: boolean;
  onExpandCard: (cardId: string) => void;
  setOpenModal: (modalType: IOpenModal) => void;
}

export const getPostalCode = (reservation: HomesReservation): string => {
  const { listing } = reservation;
  const state = listing.state ? `${listing.state},` : "";
  return `${state} ${listing.countryCode || ""}`;
};

export const addHomeType = (home: HomesItinerary): ItineraryWithType => ({
  ...home,
  type: ItineraryEnum.Home,
});

export const DesktopHomeCard = (props: IDesktopHomeCardProps) => {
  const {
    expandedCard,
    home,
    isMobile,
    onExpandCard,
    banner,
    isCancel,
    confirmationCodeClassName,
    setOpenModal,
    isPastTrips = false,
  } = props;
  const { paymentBreakdown, reservation, paymentsAndCredits } = home;
  const { calculatedTripTotal } = calculateHomeTotals(
    reservation,
    paymentBreakdown
  );

  const { matchesMobile } = useDeviceTypes();

  const actions: IActionLink[] = [];

  if (!isCancel && !isPastTrips) {
    actions.push({
      content: textConstants.CANCEL_VACATION_RENTAL,
      onClick: () => onOpenModal(MyTripsModalTypes.CancelHome),
    });
  }

  actions.push({
    content: textConstants.RESEND_CONFIRMATION,
    onClick: () => onOpenModal(MyTripsModalTypes.ResendConfirmation),
  });

  if (!isCancel) {
    actions.push({
      content: textConstants.VIEW_ITEMIZED_RECEIPT,
      onClick: () => onOpenModal(MyTripsModalTypes.ItemizedHomeReceipt),
    });
  }

  const onOpenModal = (type: MyTripsModalTypes) =>
    setOpenModal({
      type,
      selectedItinerary: addHomeType(home),
    });

  return (
    <Box
      id={reservation.id.value}
      key={reservation.id.value}
      className={clsx("home-trip-container", {
        mobile: isMobile,
        expanded: expandedCard === reservation.id.value,
      })}
    >
      {reservation.listingCollection === ListingCollectionEnum.Premier && (
        <Icon name={IconName.PremierCollectionRibbon} />
      )}
      {reservation.listingCollection === ListingCollectionEnum.Lifestyle && (
        <Icon name={IconName.LifestyleCollectionRibbon} />
      )}
      <MyTripsCard
        className="trip-card-title"
        banner={banner}
        content={
          <TripInfoDetails
            hideTitleTag={isCancel}
            confirmationCodeClassName={confirmationCodeClassName}
            titles={{
              confirmationLabel: textConstants.CONFIRMATION,
              startLabel: `${textConstants.CHECK_IN}:`,
              endLabel: `${textConstants.CHECK_OUT}:`,
              ...textConstants.getHomeInfoDetails(home),
              subtitle: reservation.listing.name || "",
            }}
            className="homes-card"
          />
        }
        actions={<ActionLinks actions={actions} />}
        expandString={
          expandedCard === reservation.id.value
            ? textConstants.VIEW_LESS
            : textConstants.VIEW_DETAILS
        }
        expandIcon={
          expandedCard === reservation.id.value
            ? IconName.MinusBlueCircle
            : IconName.PlusBlueCircle
        }
        isExpanded={expandedCard === reservation.id.value}
        topRightAction={null}
        onExpandClick={() => {
          onExpandCard(reservation.id.value);
          setTimeout(() => {
            const OFFSET = matchesMobile
              ? MOBILE_OFFSET_SCROLL
              : DESKTOP_OFFSET_SCROLL;
            const cardTop =
              document
                ?.getElementById(reservation.id.value)
                ?.getBoundingClientRect().top || 0;
            window.scrollBy({
              top: (cardTop as number) - OFFSET,
              behavior: matchesMobile ? "auto" : "smooth",
            });
          }, 100);
        }}
      />
      {reservation.id.value === expandedCard && (
        <Box className="home-desktop-expanded-container">
          <Box className="home-summary-container">
            <HomeMap home={home} />
            <SummaryCard className="home-details-list">
              <Box className="home-type">
                <Typography className="home-type-title">
                  {textConstants.VACATION_RENTAL_TYPE}
                </Typography>
                <VacationRentalOccupancyInfo
                  maxGuestsOccupancy={reservation.listing.maxGuests}
                  bedsCount={reservation.listing.numberOfBeds}
                />
              </Box>
            </SummaryCard>
            <SummaryCard className="home-details-list">
              <Box className="home-check-in">
                <HomeCheckInCheckOutPolicy home={home} />
              </Box>
            </SummaryCard>
            <SummaryCard className="home-details-list">
              <Box className="home-cancellation">
                <HomeCancellationPolicy
                  cancellationPolicy={reservation.cancellationPolicy}
                />
              </Box>
            </SummaryCard>
          </Box>
          <Box className={clsx("summary-info-container")}>
            <SummaryCard
              className="travellers-summary"
              action={
                <ActionLink
                  className="details-link"
                  onClick={() => onOpenModal(MyTripsModalTypes.TravelersModal)}
                  content="Details"
                />
              }
            >
              <TravelersSummary
                label={textConstants.GUEST_INFORMATION}
                travelers={
                  reservation.primaryGuest.givenName +
                  " " +
                  reservation.primaryGuest.surname
                }
              />
            </SummaryCard>
            <SummaryCard
              className="payment-summary"
              action={
                <ActionLink
                  className="details-link"
                  onClick={() => onOpenModal(MyTripsModalTypes.PaymentModal)}
                  content="Details"
                />
              }
            >
              <PaymentSummary
                tripTotalAmount={getCurrencyString({
                  currencyCode: calculatedTripTotal.currencyCode,
                  currencySymbol: calculatedTripTotal.currencySymbol,
                  value: calculatedTripTotal.value,
                })}
                cardLabel={getLabels(paymentsAndCredits).cardLabel}
                cardAmount={getValues(paymentsAndCredits).cardValue}
                rewardsLabel={getLabels(paymentsAndCredits).rewardLabel}
                rewardsAmount={getValues(paymentsAndCredits).rewardValue}
                showCardLabel={Boolean(getLabels(paymentsAndCredits).cardLabel)}
                showRewardsLabel={Boolean(
                  getLabels(paymentsAndCredits).rewardLabel
                )}
              />{" "}
            </SummaryCard>
            <SummaryCard className="confirmation-summary">
              <ConfirmationSummary confirmationCode={reservation.id.value} />
            </SummaryCard>
          </Box>
        </Box>
      )}
    </Box>
  );
};
