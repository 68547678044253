import { createSelector } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import {
  TravelItineraryEnum,
  ExerciseEligibilityV1Enum,
  EligibilitySliceIsEligible,
  Cap1DpExerciseFactsProperties,
  Cap1DpExerciseItineraryFactsProperties,
  PartialFlightsListDpExerciseFacts,
  PartialEligibilityDpExerciseFacts,
  EligibilitySegmentEnum,
  EligibilitySegmentIsEligible,
  CallState,
  getDepartureSlice,
  getReturnSlice,
  getEligibleSliceFromEligibility,
  BookedFlightItineraryWithDepartureTime,
  DisruptionProtectionRebookEnum,
  PolicyDetailsEnum,
  getDisruptionProductTypeFromAncillaries,
  getCoverageAndPremiumFromDisruptionAncillaries,
  getPartialEligibilityDpExerciseFactsPropertiesFromEligibility,
  getSlicesFromTravelItinerary,
  PartialDisruptedFlightDpExerciseFacts,
  getItineraryDepartureDate,
  getItineraryReturnDate,
  getSegmentCounts,
  getSegmentStopCounts,
  getIsSelectingReturnFlightForRebook,
  ExerciseEligibilityV1,
} from "redmond";
import { roundToTwoDecimals } from "halifax";
import {
  getAreSomeFlightFetchingsInProcess,
  upcomingItinerariesSelector,
  getAirportMap,
  getFlights,
} from "../../../TripsList/reducer/selectors";
import { IStoreState } from "../../../../reducers/types";
import { ActiveExperiments, AVAILABLE } from "../../../../context/experiments";
import { getProductRedeemChoiceFromAncillaries } from "./utils";
import {
  hasUpcomingReturnFlightInBookedItinerary,
  isBeforeDisruptionProtectionDeadlineTime,
  isViMultiTicket,
} from "../../../TripsList/utils";
import {
  BookedFlightItinerary,
  isMultiCityItinerary,
} from "redmond/trips-module/itinerary";

export const getDisruptionProtectionExperimentsSelector = (
  state: IStoreState
) => state.disruptionProtection.experiments;

export const getSelectedFlightDisruptionProtectionItineraryId = (
  state: IStoreState
) => state.disruptionProtection.selectedDisruptionProtectionItineraryId;

export const getFlightDisruptions = (state: IStoreState) =>
  state.disruptionProtection.flightDisruptions;

export const getFetchFlightDisruptionsCallState = (state: IStoreState) =>
  state.disruptionProtection.fetchFlightDisruptionsCallState;

export const getPreparedRefundInfo = (state: IStoreState) =>
  state.disruptionProtection.preparedRefundInfo;

export const getFetchDisruptionPreparedFlightRefundCallState = (
  state: IStoreState
) => state.disruptionProtection.fetchDisruptionPreparedFlightRefundCallState;

export const getDisruptionFlightRefundResult = (state: IStoreState) =>
  state.disruptionProtection.disruptionFlightRefundResult;

export const getProductRedeemChoice = (state: IStoreState) =>
  state.disruptionProtection.productRedeemChoice;

export const getSubmitDisruptionFlightRefundCallState = (state: IStoreState) =>
  state.disruptionProtection.submitDisruptionFlightRefundCallState;

export const getSelectedSliceIndexForMCPVirtualInterline = (
  state: IStoreState
) => state.disruptionProtection.sliceIndex;

export const getDisruptionProtection24hRuleVariant = createSelector(
  getDisruptionProtectionExperimentsSelector,
  (disruptionProtectionExperiments) => {
    return disruptionProtectionExperiments?.[
      ActiveExperiments.DisruptionProtection24hRule
    ];
  }
);

export const isDisruptionProtection24hRuleEnabledSelector = createSelector(
  getDisruptionProtection24hRuleVariant,
  (disruptionProtection24hRuleVariant): boolean => {
    return disruptionProtection24hRuleVariant === AVAILABLE;
  }
);

export const isDisruptionProtection24hRuleSatisfiedSelector = createSelector(
  isDisruptionProtection24hRuleEnabledSelector,
  (
    isDisruptionProtection24hRuleEnabled
  ): ((localDeparture: string | undefined) => boolean) => {
    return (localDeparture: string | undefined) => {
      if (isDisruptionProtection24hRuleEnabled && localDeparture) {
        /*
          note: if a user has disruption protection, they should see the rapid rebooking button
          on their trip card starting only once the trip is within 24 hours of departure.
        */
        return dayjs().isAfter(dayjs(localDeparture).subtract(1, "day"));
      }
      // note: when the 24-hour rule is not enabled (or departure is not defined), it's vacuously true
      else {
        return true;
      }
    };
  }
);

export const isRapidRebookRenameEnabledSelector = createSelector(
  getDisruptionProtectionExperimentsSelector,
  (experiments) => {
    return experiments?.[ActiveExperiments.RAPID_REBOOK_RENAME] === AVAILABLE;
  }
);

export const isPostBookingOfferMyTripsEnabledSelector = createSelector(
  getDisruptionProtectionExperimentsSelector,
  (experiments) => {
    return (
      experiments?.[ActiveExperiments.POST_BOOKING_OFFER_MY_TRIPS] === AVAILABLE
    );
  }
);

export const isFintechCsatEnabledSelector = createSelector(
  getDisruptionProtectionExperimentsSelector,
  (experiments) => experiments?.[ActiveExperiments.FINTECH_CSAT] === AVAILABLE
);

export const getAreSomeDisruptionInfoFetchingsInProcess = createSelector(
  getAreSomeFlightFetchingsInProcess,
  getFetchFlightDisruptionsCallState,
  getFetchDisruptionPreparedFlightRefundCallState,
  (
    areSomeFlightFetchingsInProcess,
    fetchFlightDisruptionsCallState,
    fetchDisruptionPreparedFlightRefundCallState
  ): boolean => {
    return (
      areSomeFlightFetchingsInProcess ||
      fetchFlightDisruptionsCallState === CallState.InProcess ||
      fetchDisruptionPreparedFlightRefundCallState === CallState.InProcess
    );
  }
);

export const getDpAttachedFlightItineraries = createSelector(
  [getFlights, isDisruptionProtection24hRuleSatisfiedSelector],
  (flightItineraries, isDisruptionProtection24hRuleSatisfied) => {
    const timeEligibleFlights = [
      ...flightItineraries.present,
      ...flightItineraries.future,
      ...flightItineraries.past,
    ];

    return timeEligibleFlights.filter((flight) => {
      const { ancillaries, departureTime } = flight;

      const hasDisruptionAncillary =
        !!ancillaries?.delay ||
        !!ancillaries?.missedConnection ||
        !!ancillaries?.missedConnectionVi;

      const is24hRuleSatisfied =
        isDisruptionProtection24hRuleSatisfied(departureTime);

      return hasDisruptionAncillary && is24hRuleSatisfied;
    });
  }
);
export const getDpExerciseEligibleFlightItinerariesWihtout24hRule =
  createSelector(
    [getFlights, getFlightDisruptions],
    (flightItineraries, flightDisruptions) => {
      const pastFlights: BookedFlightItineraryWithDepartureTime[] = [
        ...flightItineraries.past,
      ];

      const timeEligiblePastFlights = pastFlights.filter((f) => {
        const dpExerciseEligibility: ExerciseEligibilityV1 | undefined =
          flightDisruptions?.eligibilityByItinerary[f.bookedItinerary.id];

        return isBeforeDisruptionProtectionDeadlineTime(dpExerciseEligibility);
      });

      const timeEligibleFlights = [
        ...flightItineraries.present,
        ...flightItineraries.future,
        ...timeEligiblePastFlights,
      ];

      return timeEligibleFlights.filter((flight) => {
        const { ancillaries } = flight;
        const hasDisruptionAncillary =
          !!ancillaries?.delay ||
          !!ancillaries?.missedConnection ||
          !!ancillaries.missedConnectionVi;
        return hasDisruptionAncillary;
      });
    }
  );

export const getDpAttachedFlightItineraryIds = createSelector(
  [
    getDpAttachedFlightItineraries,
    getDpExerciseEligibleFlightItinerariesWihtout24hRule,
  ],
  (flightItineraries, flightItinerariesWihout24Rule) => {
    return [...flightItineraries, ...flightItinerariesWihout24Rule].map(
      (itinerary) => itinerary.bookedItinerary.id
    );
  }
);

export const getDpExerciseEligibleFlightItineraries = createSelector(
  [
    getFlights,
    getFlightDisruptions,
    isDisruptionProtection24hRuleSatisfiedSelector,
  ],
  (
    flightItineraries,
    flightDisruptions,
    isDisruptionProtection24hRuleSatisfied
  ) => {
    const pastFlights: BookedFlightItineraryWithDepartureTime[] = [
      ...flightItineraries.past,
    ];

    const timeEligiblePastFlights = pastFlights.filter((f) => {
      const dpExerciseEligibility: ExerciseEligibilityV1 | undefined =
        flightDisruptions?.eligibilityByItinerary[f.bookedItinerary.id];

      return isBeforeDisruptionProtectionDeadlineTime(dpExerciseEligibility);
    });

    const timeEligibleFlights = [
      ...flightItineraries.present,
      ...flightItineraries.future,
      ...timeEligiblePastFlights,
    ];

    return timeEligibleFlights.filter((flight) => {
      const { ancillaries, departureTime } = flight;
      const hasDisruptionAncillary =
        !!ancillaries?.delay ||
        !!ancillaries?.missedConnection ||
        !!ancillaries.missedConnectionVi;
      const is24hRuleSatisfied =
        isDisruptionProtection24hRuleSatisfied(departureTime);

      return hasDisruptionAncillary && is24hRuleSatisfied;
    });
  }
);

export const getDpExerciseEligibleFlightItineraryIds = createSelector(
  [getDpExerciseEligibleFlightItineraries],
  (flightItineraries) => flightItineraries.map((i) => i.bookedItinerary.id)
);

export const selectedFlightDisruptionProtectionItinerarySelectorAgentPortal =
  createSelector(
    [
      getDpExerciseEligibleFlightItinerariesWihtout24hRule,
      getSelectedFlightDisruptionProtectionItineraryId,
    ],
    (flights, itineraryId) => {
      return flights?.find(
        (flight) => flight.bookedItinerary.id === itineraryId
      );
    }
  );

export const selectedFlightDisruptionProtectionItinerarySelector =
  createSelector(
    [
      getDpExerciseEligibleFlightItineraries,
      getSelectedFlightDisruptionProtectionItineraryId,
    ],
    (flights, itineraryId) => {
      return flights?.find(
        (flight) => flight.bookedItinerary.id === itineraryId
      );
    }
  );

export const selectedFlightDisruptionProtectionItineraryIdSelector =
  createSelector(
    getSelectedFlightDisruptionProtectionItineraryId,
    (itineraryId) => itineraryId
  );

export const selectedFlightDisruptionAncillaryCoverageSelector = createSelector(
  selectedFlightDisruptionProtectionItinerarySelector,
  (itinerary) => {
    const delayCoverage = itinerary?.ancillaries.delay?.policyDetails?.coverage;
    const missedConnectionPolicyDetails =
      itinerary?.ancillaries.missedConnection?.policyDetails;
    const missedConnectionCoverage = (() => {
      switch (missedConnectionPolicyDetails?.PolicyDetails) {
        case PolicyDetailsEnum.FixedPolicyDetails:
          return missedConnectionPolicyDetails.coverage;
        case PolicyDetailsEnum.PercentagePolicyDetails:
        default:
          return undefined;
      }
    })();

    return delayCoverage ?? missedConnectionCoverage;
  }
);

export const selectedFlightDisruptionProtectionItinerarySlicesSelector =
  createSelector(
    [selectedFlightDisruptionProtectionItinerarySelector],
    (itinerary) => {
      const travelItinerary = itinerary?.bookedItinerary.travelItinerary;

      if (travelItinerary) return getSlicesFromTravelItinerary(travelItinerary);
      else return undefined;
    }
  );

export const selectedFlightDisruptionProtectionItineraryDepartureSliceSelector =
  createSelector(
    [selectedFlightDisruptionProtectionItinerarySlicesSelector],
    (slices) => slices?.[0]
  );

export const selectedFlightDisruptionProtectionItineraryReturnSliceSelector =
  createSelector(
    [selectedFlightDisruptionProtectionItinerarySlicesSelector],
    (slices) => slices?.[1]
  );

export const selectedFlightDisruptionProtectionItineraryCoverageCapSelector =
  createSelector(
    selectedFlightDisruptionProtectionItinerarySelector,
    (itinerary) => {
      if (itinerary) {
        return getCoverageAndPremiumFromDisruptionAncillaries(
          itinerary.ancillaries
        )?.coverage;
      }

      return undefined;
    }
  );

export const selectedFlightDisruptionProtectionEligibilitySelector =
  createSelector(
    [getFlightDisruptions, getSelectedFlightDisruptionProtectionItineraryId],
    (flightDisruptions, itineraryId) => {
      if (itineraryId) {
        return flightDisruptions.eligibilityByItinerary[itineraryId];
      }

      return undefined;
    }
  );

// note: departure slice is at index 0, and return slice is at index 1
export const selectedFlightDisruptionProtectionEligibleSliceSelector =
  createSelector(
    selectedFlightDisruptionProtectionEligibilitySelector,
    (
      eligibility
    ): { slice: EligibilitySliceIsEligible; index: 0 | 1 } | undefined => {
      switch (eligibility?.ExerciseEligibilityV1) {
        case ExerciseEligibilityV1Enum.IsEligible:
          return getEligibleSliceFromEligibility(eligibility);
        case ExerciseEligibilityV1Enum.NotEligible:
        default:
          return undefined;
      }
    }
  );

export const selectedFlightDisruptionProtectionEligibleSegmentSelector =
  createSelector(
    selectedFlightDisruptionProtectionEligibleSliceSelector,
    (
      eligibleSlice
    ): { segment: EligibilitySegmentIsEligible; index: number } | undefined => {
      const segments = eligibleSlice?.slice.segments;
      const segmentIndex = segments?.findIndex(
        (segment) =>
          segment.EligibilitySegment === EligibilitySegmentEnum.IsEligible
      );

      if (
        segments !== undefined &&
        segmentIndex !== undefined &&
        segmentIndex >= 0
      ) {
        return {
          segment: segments[segmentIndex] as EligibilitySegmentIsEligible,
          index: segmentIndex,
        };
      }

      return undefined;
    }
  );

export const isSelectingReturnFlightSelector = createSelector(
  selectedFlightDisruptionProtectionEligibilitySelector,
  selectedFlightDisruptionProtectionItineraryDepartureSliceSelector,
  selectedFlightDisruptionProtectionItineraryReturnSliceSelector,
  (eligibility, departureSlice, returnSlice): boolean => {
    return getIsSelectingReturnFlightForRebook({
      eligibility,
      departureSlice,
      returnSlice,
    });
  }
);

export const selectedFlightDisruptionProtectionEligibleTravelItinerarySegmentSelector =
  createSelector(
    selectedFlightDisruptionProtectionEligibleSliceSelector,
    selectedFlightDisruptionProtectionEligibleSegmentSelector,
    selectedFlightDisruptionProtectionItinerarySelector,
    (eligibleSlice, eligibleSegment, itinerary) => {
      const travelItinerary = itinerary?.bookedItinerary.travelItinerary;

      if (eligibleSlice && eligibleSegment && travelItinerary) {
        switch (travelItinerary.TravelItinerary) {
          case TravelItineraryEnum.SingleTravelItinerary:
            return travelItinerary.slices[eligibleSlice.index]?.segments[
              eligibleSegment.index
            ];
          case TravelItineraryEnum.MultiTravelItinerary:
            return travelItinerary.travelItineraries[eligibleSlice.index]
              ?.slices[0]?.segments[eligibleSegment.index];
          default:
            return undefined;
        }
      }

      return undefined;
    }
  );

export const originalItineraryIdToRebookedItineraryMapSelector = createSelector(
  [getFlights],
  (
    flightItineraries
  ): { [key in string]: BookedFlightItineraryWithDepartureTime } => {
    const rebookedFlights: {
      [key in string]: BookedFlightItineraryWithDepartureTime;
    } = {};

    [
      ...flightItineraries.past,
      ...flightItineraries.present,
      ...flightItineraries.future,
    ].forEach((flight) => {
      const disruption = flight.disruptionProtectionRebook;
      if (
        disruption?.DisruptionProtectionRebook ===
        DisruptionProtectionRebookEnum.IsRebook
      ) {
        rebookedFlights[disruption.originalItineraryId] = flight;
      }
    });

    return rebookedFlights;
  }
);

export const hasUpcomingReturnFlightInBookedItineraryAfterRebookSelector =
  createSelector(
    originalItineraryIdToRebookedItineraryMapSelector,
    getAirportMap,
    (
      rebookedItineraries,
      airportMap
    ): ((itinerary: BookedFlightItinerary | undefined) => boolean) => {
      return (itinerary: BookedFlightItinerary | undefined) => {
        // multicity itineraries cannot have rebook
        if (!itinerary || isMultiCityItinerary(itinerary, airportMap)) {
          return false;
        }

        const hasUpcomingReturnFlight =
          hasUpcomingReturnFlightInBookedItinerary(itinerary);
        const hasRebookedReturnFlight = (() => {
          const rebookedItinerary = rebookedItineraries[itinerary.id];

          if (rebookedItinerary) {
            const originalReturnSegments = getReturnSlice(itinerary)?.segments;
            const originalReturnDestination = originalReturnSegments
              ? originalReturnSegments[originalReturnSegments.length - 1]
                  .destination.locationCode
              : undefined;

            // the rebooked itinerary will be a single-slice itinerary
            const rebookedSegments = getDepartureSlice(
              rebookedItinerary.bookedItinerary
            )?.segments;
            const rebookedDestination = rebookedSegments
              ? rebookedSegments[rebookedSegments.length - 1].destination
                  .locationCode
              : undefined;

            // when both destinations are present and equal, it implies that the rebooked slice was the return slice
            return (
              !!originalReturnDestination &&
              !!rebookedDestination &&
              originalReturnDestination === rebookedDestination
            );
          }

          return false;
        })();

        return hasUpcomingReturnFlight && !hasRebookedReturnFlight;
      };
    }
  );

export const cap1DpExerciseItineraryFactsPropertiesSelector = createSelector(
  selectedFlightDisruptionProtectionItinerarySelector,
  selectedFlightDisruptionProtectionEligibleSliceSelector,
  (
    itinerary,
    eligibilitySlice
  ):
    | Omit<
        Cap1DpExerciseItineraryFactsProperties,
        "success" | "exercise_choice"
      >
    | undefined => {
    if (itinerary) {
      const sellingPricing = itinerary.bookedItinerary.sellingPricing;
      const pricingByPassenger = sellingPricing.pricingByPassenger[0];
      const travelItinerary = itinerary.bookedItinerary.travelItinerary;
      const locators = travelItinerary.locators;
      const delay = itinerary.ancillaries.delay;
      const missedConnection = itinerary.ancillaries.missedConnection;
      const source = itinerary.bookedItinerary.source;
      const passengers = itinerary.bookedItinerary.passengers;
      const passengersCount = (() => {
        let originalPassengersSet = new Set<string>();
        passengers?.alone.forEach((passenger) =>
          originalPassengersSet.add(passenger.person.id)
        );
        passengers?.withLapInfants.forEach((entry) => {
          originalPassengersSet.add(entry.adult.person.id);
          originalPassengersSet.add(entry.infant.person.id);
        });
        return originalPassengersSet.size;
      })();
      const slices = getSlicesFromTravelItinerary(travelItinerary);
      const slice = (() => {
        if (slices && eligibilitySlice) {
          return slices[eligibilitySlice.index];
        }
        return undefined;
      })();
      const firstSegment = slice?.segments[0];
      const lastSegment = slice
        ? slice.segments[slice.segments.length - 1]
        : undefined;
      const tripType =
        slices !== undefined
          ? slices.length > 1
            ? "round_trip"
            : "one_way"
          : undefined;

      return {
        base_total_usd:
          sellingPricing.totalPricing.baseWithoutMargin.fiat.value,
        departure_date:
          firstSegment?.zonedUpdatedDeparture ?? firstSegment?.updatedDeparture,
        destination: lastSegment?.destination.locationCode,
        disruption_original_agent_locator: locators?.agent.unscopedValue,
        disruption_original_system_locator: locators?.b2b,
        disruption_original_trip_cost: pricingByPassenger.subtotal.fiat.value,
        disruption_policy_id:
          delay?.id.policyId ?? missedConnection?.id.policyId,
        disruption_premium: getCoverageAndPremiumFromDisruptionAncillaries(
          itinerary.ancillaries
        )?.premium.amount,
        disruption_product_id:
          delay?.id.productId ?? missedConnection?.id.productId,
        origin: firstSegment?.origin.locationCode,
        itinerary_create_date: source?.created,
        outbound_local_departure_date:
          firstSegment?.zonedUpdatedDeparture ??
          firstSegment?.zonedScheduledDeparture ??
          firstSegment?.updatedDeparture,
        pax_total: passengersCount,
        price_per_pax_usd:
          // note: pricingByPassenger.total does not seem to include ancillary prices - not sure if that's intended
          roundToTwoDecimals(
            pricingByPassenger.subtotal.fiat.value +
              pricingByPassenger.ancillaries.reduce(
                (sum, ancillary) => sum + ancillary.premium.fiat.value,
                0
              )
          ),
        subtotal_usd: sellingPricing.totalPricing.subtotal.fiat.value,
        total_price: sellingPricing.totalPricing.total.fiat.value,
        total_price_usd: sellingPricing.totalPricing.total.fiat.value,
        trip_type: tripType,
        multi_ticket_type: itinerary.bookedItinerary.multiTicketType,
      };
    }
    return undefined;
  }
);

export const selectedFlightDisruptionProtectionEligibleSliceDestinationSelector =
  createSelector(
    selectedFlightDisruptionProtectionEligibleSliceSelector,
    selectedFlightDisruptionProtectionItinerarySelector,
    getAirportMap,
    (eligibleSlice, itinerary, airportMap): string | undefined => {
      if (eligibleSlice && itinerary) {
        const travelItinerary = itinerary.bookedItinerary.travelItinerary;

        switch (travelItinerary.TravelItinerary) {
          case TravelItineraryEnum.SingleTravelItinerary: {
            const singleSliceSegments =
              travelItinerary.slices[eligibleSlice.index]?.segments;
            const singleLastSegmentDestination =
              singleSliceSegments[singleSliceSegments.length - 1]?.destination;
            return airportMap[singleLastSegmentDestination.locationCode]
              ?.cityName;
          }
          case TravelItineraryEnum.MultiTravelItinerary: {
            const multipleSliceSegments =
              travelItinerary.travelItineraries[eligibleSlice.index]?.slices[0]
                ?.segments;
            const multiplelLastSegmentDestination =
              multipleSliceSegments[multipleSliceSegments.length - 1]
                ?.destination;
            return airportMap[multiplelLastSegmentDestination.locationCode]
              ?.cityName;
          }
          default:
            return undefined;
        }
      }

      return undefined;
    }
  );

export const myTripsPartialFlightsListDpExerciseFactsPropertiesSelector =
  createSelector(
    [upcomingItinerariesSelector],
    (upcomingItineraries): PartialFlightsListDpExerciseFacts | undefined => {
      const flights = upcomingItineraries.flights;
      const disruptionDetails: PartialFlightsListDpExerciseFacts =
        flights.reduce(
          (details: PartialFlightsListDpExerciseFacts, flight) => {
            const {
              disruption_attached: attached = 0,
              disruption_product: product,
            } = details;
            const delay = flight.ancillaries.delay;
            const missedConnection = flight.ancillaries.missedConnection;
            const newProduct = (() => {
              if (
                (product === "delays" && !!missedConnection) ||
                (product === "missed_connections" && !!delay)
              ) {
                return "both";
              } else if (product === undefined) {
                if (!!delay) return "delays";
                if (!!missedConnection) return "missed_connections";
              }
              return product;
            })();

            return {
              disruption_attached:
                attached + (!!delay || !!missedConnection ? 1 : 0),
              disruption_product: newProduct,
            };
          },
          {
            disruption_attached: 0,
            disruption_product: undefined,
          }
        );

      if (disruptionDetails.disruption_product !== undefined) {
        return disruptionDetails;
      }

      return undefined;
    }
  );

export const myTripsPartialEligibilityDpExerciseFactsPropertiesSelector =
  createSelector(
    [getFlightDisruptions],
    (flightDisruptions): PartialEligibilityDpExerciseFacts | undefined => {
      const disruptions = Object.values(
        flightDisruptions.eligibilityByItinerary
      );

      if (disruptions.length > 0) {
        const activeDisruption = disruptions.find(
          (disruption) =>
            disruption.ExerciseEligibilityV1 ===
            ExerciseEligibilityV1Enum.IsEligible
        );
        return getPartialEligibilityDpExerciseFactsPropertiesFromEligibility(
          activeDisruption
        );
      }

      return undefined;
    }
  );

export const getMcpViitineraries = createSelector(
  [getFlights],
  (flightItineraries) => {
    const timeEligibleFlights = [
      ...flightItineraries.present,
      ...flightItineraries.future,
      ...flightItineraries.past,
    ];

    return timeEligibleFlights.filter((flight) =>
      isViMultiTicket(flight.bookedItinerary.multiTicketType)
    );
  }
);

export const disruptedFlightDpExerciseFactsPropertiesSelector = createSelector(
  [
    selectedFlightDisruptionProtectionItinerarySelector,
    selectedFlightDisruptionProtectionEligibleTravelItinerarySegmentSelector,
  ],
  (itinerary, segment): PartialDisruptedFlightDpExerciseFacts | undefined => {
    const travelItinerary = itinerary?.bookedItinerary.travelItinerary;

    if (travelItinerary && segment) {
      const segmentCounts = getSegmentCounts(travelItinerary);
      const segmentStopCounts = getSegmentStopCounts(travelItinerary);

      return {
        departure_date:
          segment.zonedUpdatedDeparture ?? segment.updatedDeparture,
        itinerary_departure_date: getItineraryDepartureDate(travelItinerary),
        itinerary_return_date: getItineraryReturnDate(travelItinerary),
        advance: dayjs(segment.zonedUpdatedDeparture).diff(dayjs(), "days"),
        destination: segment.destination.locationCode,
        origin: segment.origin.locationCode,
        outbound_segments: segmentCounts.outboundFlight,
        outbound_stops: segmentStopCounts.outboundFlight,
        return_segments: segmentCounts.returnFlight,
        return_stops: segmentStopCounts.returnFlight,
      };
    }

    return undefined;
  }
);

export const disruptionOverviewEligibilityDpExerciseFactsPropertiesSelector =
  createSelector(
    [
      selectedFlightDisruptionProtectionItinerarySelector,
      selectedFlightDisruptionProtectionEligibilitySelector,
    ],
    (
      selectedItinerary,
      selectedEligibility
    ):
      | (PartialFlightsListDpExerciseFacts & PartialEligibilityDpExerciseFacts)
      | undefined => {
      if (selectedItinerary && selectedEligibility) {
        return {
          ...getPartialEligibilityDpExerciseFactsPropertiesFromEligibility(
            selectedEligibility
          ),
          disruption_product: getDisruptionProductTypeFromAncillaries(
            selectedItinerary.ancillaries
          ),
        };
      }

      return undefined;
    }
  );

export const eligibilityDpExerciseProductRedeemChoiceSelector = createSelector(
  [selectedFlightDisruptionProtectionItinerarySelector, getProductRedeemChoice],
  (
    selectedItinerary,
    productRedeemChoice
  ):
    | Pick<Cap1DpExerciseFactsProperties, "product_redeem_choice">
    | undefined => {
    if (!!productRedeemChoice || !!selectedItinerary) {
      return {
        product_redeem_choice:
          productRedeemChoice ??
          getProductRedeemChoiceFromAncillaries(selectedItinerary?.ancillaries),
      };
    }

    return undefined;
  }
);
