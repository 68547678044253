import React from "react";
import { Box, Typography } from "@material-ui/core";
import { Icon, IconName, useDeviceTypes } from "halifax";
import clsx from "clsx";
import * as constants from "./constants";
import "./styles.scss";
import { DisruptionProtectionBannerConnectorProps } from "./container";

export interface IDisruptionProtectionBannerProps
  extends DisruptionProtectionBannerConnectorProps {
  isMissedConnectionProtecion: boolean;
}

export const DisruptionProtectionBanner = ({
  isRapidRebookRenameEnabled,
  isMissedConnectionProtecion,
}: IDisruptionProtectionBannerProps) => {
  const { matchesMobile } = useDeviceTypes();

  return (
    <Box
      className={clsx("disruption-protection-banner-header-root", {
        mobile: matchesMobile,
      })}
    >
      <Box className="disruption-protection-banner-header-details">
        <Box className="header-details-wrapper">
          <Box className="icon-section">
            <Icon name={IconName.DisruptionProtection} />
          </Box>
          <Box className="details-section">
            <Box className="disruption-protection-banner-header-title">
              <Typography className="title-text">
                {isMissedConnectionProtecion
                  ? constants.DISRUPTION_MCP_BANNER_TITLE
                  : isRapidRebookRenameEnabled
                    ? constants.DISRUPTION_PROTECTION_BANNER_TITLE_NEW
                    : constants.DISRUPTION_PROTECTION_BANNER_TITLE_OLD}
              </Typography>
            </Box>
            <Box className="disruption-protection-banner-header-subtitle">
              <Typography className="subtitle-text">
                {isMissedConnectionProtecion ? constants.DISRUPTION_MCP_BANNER_SUBTITLE : constants.DISRUPTION_PROTECTION_BANNER_SUBTITLE}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className="disruption-protection-banner-header-image" />
    </Box>
  );
};
