import React, { useRef, useEffect } from "react";
import { RouteComponentProps, StaticContext } from "react-router";
import { Box, Typography } from "@material-ui/core";
import {
  Icon,
  IconName,
  MyTripsMobileCard,
  BannerSeverity,
  NotificationBanner,
} from "halifax";
import clsx from "clsx";
import {
  HomesItinerary,
  HomesReservationStatus,
  ListingCollectionEnum,
  // IOpenModal,
} from "redmond";

import * as textConstants from "./constants";
import { scrollTopWithOffset } from "../../component";
import "./styles.scss";
import { DesktopHomeCard } from "./components/DesktopHomeCard";
import { HomeCardConnectorProps } from "./container";

export const getStatusBanner = (status?: HomesReservationStatus) => {
  switch (status) {
    case HomesReservationStatus.Cancelled:
      return (
        <NotificationBanner
          className="status-banner"
          label={textConstants.CANCELED_RES}
          severity={BannerSeverity.ERROR}
          icon={<Icon name={IconName.ErrorAlert} />}
        />
      );
    default:
      return undefined;
  }
};

interface IHomeCardProps
  extends HomeCardConnectorProps,
    RouteComponentProps<{}, StaticContext, { prevPath?: string }> {
  isMobile?: boolean;
  home: HomesItinerary;
  expandedCard: string;
  onExpandCard: (cardId: string) => void;
}

export const HomeCard = ({
  history,
  home,
  isMobile,
  setSelectedHome,
  populateTripQueryParams,
  expandedCard,
  isPastTrips,
  onExpandCard,
  setOpenModal,
}: IHomeCardProps) => {
  const homeRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (
      !isMobile &&
      homeRef?.current &&
      expandedCard === home.reservation.id.value
    ) {
      scrollTopWithOffset(homeRef.current);
    }
  }, [expandedCard]);

  const reservationState = home.reservation.status.ReservationStatus;
  const statusBanner = getStatusBanner(reservationState);
  const isCancel = reservationState == HomesReservationStatus.Cancelled;
  const confirmationCodeClassName = isCancel ? "warning" : "";
  return (
    <Box className={clsx({ mobile: isMobile }, "home-list")}>
      {isMobile ? (
        <MyTripsMobileCard
          onClick={() => {
            setSelectedHome(home);
            populateTripQueryParams(history);
          }}
          banner={statusBanner}
          confirmationCodeClassName={confirmationCodeClassName}
          confirmationLabel={textConstants.CONFIRMATION}
          label={textConstants.MOBILE_LABEL}
          icon={IconName.HomeIcon}
          {...textConstants.getHomeInfoDetails(home)}
        />
      ) : (
        <div ref={homeRef}>
          <Box className="home-card-icon-title">
            <Icon name={IconName.HomeIcon} />
            <Typography variant="body1" className="summary-label">
              {textConstants.getHeaderText(
                home.reservation.stayDates.from,
                home.reservation.stayDates.until,
                home.reservation.listingCollection ===
                  ListingCollectionEnum.Premier,
                home.reservation.listingCollection ===
                  ListingCollectionEnum.Lifestyle
              )}
            </Typography>
          </Box>
          <DesktopHomeCard
            banner={statusBanner}
            isPastTrips={isPastTrips}
            home={home}
            isCancel={isCancel}
            confirmationCodeClassName={confirmationCodeClassName}
            isMobile={isMobile}
            expandedCard={expandedCard}
            onExpandCard={onExpandCard}
            setOpenModal={setOpenModal}
            isRapidRebookRenameEnabled={false}
          />
        </div>
      )}
    </Box>
  );
};
