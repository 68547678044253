import { Box, Typography } from "@material-ui/core";
import dayjs from "dayjs";
import React from "react";
import { HomesItinerary, VRCancellationPolicy } from "redmond";
import "./styles.scss";
import * as textConstants from "./textConstants";

export const HomeCheckInCheckOutPolicy = ({
  home,
  includeCheckInInfoCopy = false,
}: {
  home: HomesItinerary;
  includeCheckInInfoCopy?: boolean;
}) => {
  const { reservation } = home;
  const getStandardTime = (time: string) => {
    return dayjs("1/1/1 " + time).format("hh:mm A"); // converts military time to standard 12 hrs time
  };

  return (
    <Box className="check-in-info-container">
      {/* TODO: VR  - We have a check in checkout time component already in hotels, should probably put that in components and merge with that for consistency */}

      {reservation.listing.checkInTimes?.startTime && (
        <Box className="check-in-time">
          <Typography className="info-title">
            {textConstants.CHECK_IN_TIME}
          </Typography>
          {reservation.listing.checkInTimes?.startTime && (
            <Typography variant="body2">
              From{" "}
              {getStandardTime(reservation.listing.checkInTimes?.startTime)}{" "}
              {reservation.listing.checkInTimes?.endTime &&
                `- ${getStandardTime(
                  reservation.listing.checkInTimes?.endTime
                )}`}
            </Typography>
          )}
        </Box>
      )}
      {reservation.listing.checkOutTimes?.endTime && (
        <Box className="check-in-time">
          <Typography className="info-title">
            {textConstants.CHECKOUT_TIME}
          </Typography>
          <Typography variant="body2">
            Until {getStandardTime(reservation.listing.checkOutTimes?.endTime)}
          </Typography>
        </Box>
      )}
      {includeCheckInInfoCopy && (
        <Typography variant="body2">
          {textConstants.CHECK_IN_INFO_COPY}
        </Typography>
      )}
    </Box>
  );
};

export const HomeCancellationPolicy = ({
  cancellationPolicy,
}: {
  cancellationPolicy: VRCancellationPolicy;
}) => {
  return (
    <Box className="cancellation-info-container">
      <Typography className="info-title">
        {cancellationPolicy.policy.primaryText}
      </Typography>
      {"secondaryText" in cancellationPolicy.policy && (
        <Typography variant="body2">
          {cancellationPolicy.policy.secondaryText}
        </Typography>
      )}
    </Box>
  );
};
