import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import { IStoreState } from "../../../../../../../../reducers/types";
import {
  getAirlinesMap,
  getAirportMap,
  getConfirmFlightCfarCancellationCallState,
  getFetchFlightCfarInfoCallState,
  getIsMultiCityCfar,
  getSelectedFlightCfarInfo,
  getSelectedFlightCfarItineraryId,
  isFintechCsatEnabledSelector,
} from "../../../../../../reducer/selectors";
import {
  confirmFlightCfarCancellation,
  fetchFlightCfarCancellationInfoV3,
  setFetchFlightCfarCancellationInfoV3CallStateNotCalled,
} from "../../../../../../actions/actions";
import { CfarFlightModalContent } from "./component";
import { getDefaultSelectedAccountReferenceIdIfRedemptionEnabled } from "../../../../../../../../modules/rewards/reducer";
import { isRapidRebookRenameEnabledSelector } from "../../../../../../../DisruptionProtection/reducer";

const mapStateToProps = (state: IStoreState) => ({
  airlineMap: getAirlinesMap(state),
  airportMap: getAirportMap(state),
  confirmFlightCfarCancellationCallState:
    getConfirmFlightCfarCancellationCallState(state),
  fetchFlightCfarInfoCallState: getFetchFlightCfarInfoCallState(state),
  selectedCfar: getSelectedFlightCfarInfo(state),
  isMultiCityCfar: getIsMultiCityCfar(state),
  selectedCfarItineraryId: getSelectedFlightCfarItineraryId(state),
  selectedAccountReferenceId:
    getDefaultSelectedAccountReferenceIdIfRedemptionEnabled(state),
  isRapidRebookRenameEnabled: isRapidRebookRenameEnabledSelector(state),
  isFintechCsatEnabled: isFintechCsatEnabledSelector(state),
});

const mapDispatchToProps = {
  confirmFlightCfarCancellation,
  fetchFlightCfarCancellationInfoV3,
  setFetchFlightCfarCancellationInfoV3CallStateNotCalled,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type ICfarFlightModalContentConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedCfarFlightModalContent = connector(
  withRouter(CfarFlightModalContent)
);
