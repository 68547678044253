import {
  getMcpViitineraries,
  isRapidRebookRenameEnabledSelector,
} from "./../../../../../DisruptionProtection/reducer/selectors/index";
import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { MyTripsFilter } from "redmond";
import { IStoreState } from "../../../../../../reducers/types";
import { setSelectedFlightDisruptionProtectionItineraryId } from "../../../../../DisruptionProtection/actions/actions";
import {
  getFetchFlightDisruptionsCallState,
  getFlightDisruptions,
  isDisruptionProtection24hRuleSatisfiedSelector,
  hasUpcomingReturnFlightInBookedItineraryAfterRebookSelector,
} from "../../../../../DisruptionProtection/reducer";
import {
  fetchFlights,
  setSelectedFlight,
  populateTripQueryParams,
  setOpenModal,
} from "../../../../actions/actions";
import { FlightCard } from "./component";
import {
  getAirlinesMap,
  getAirportMap,
  getMyTripsPostBookingOffers,
  getTripsFilter,
  upcomingItinerariesSelector,
} from "../../../../reducer";

const mapStateToProps = (state: IStoreState) => ({
  mcpViItineraries: getMcpViitineraries(state),
  airportMap: getAirportMap(state),
  airlineMap: getAirlinesMap(state),
  tripsFilter: getTripsFilter(state),
  isPastTrips: getTripsFilter(state) === MyTripsFilter.PAST_TRIPS,
  flightDisruptions: getFlightDisruptions(state),
  fetchFlightDisruptionsCallState: getFetchFlightDisruptionsCallState(state),
  upcomingItineraries: upcomingItinerariesSelector(state),
  isDisruptionProtection24hRuleSatisfied:
    isDisruptionProtection24hRuleSatisfiedSelector(state),
  hasUpcomingReturnFlightInBookedItineraryAfterRebook:
    hasUpcomingReturnFlightInBookedItineraryAfterRebookSelector(state),
  isRapidRebookRenameEnabled: isRapidRebookRenameEnabledSelector(state),
  myTripsPostBookingOffers: getMyTripsPostBookingOffers(state),
});

const mapDispatchToProps = {
  fetchFlights,
  setSelectedFlight,
  populateTripQueryParams,
  setOpenModal,
  setSelectedFlightDisruptionProtectionItineraryId,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type FlightCardConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedFlightCard = connector(withRouter(FlightCard));
