import {
  CallState,
  PreparedRefundInfo,
  GetExerciseEligibilitiesResponseV1,
  DisruptionFlightRefundResult,
} from "redmond";
import { actions, actionTypes } from "../actions";
import {
  DefaultVariantType,
  ActiveExperiments,
} from "../../../context/experiments";

export interface IDisruptionProtectionExperimentState {
  experiments: IDisruptionProtectionExperiments | null;
}

export interface IDisruptionProtectionState
  extends IDisruptionProtectionExperimentState {
  selectedDisruptionProtectionItineraryId: string | null;
  fetchFlightDisruptionsCallState: CallState;
  flightDisruptions: GetExerciseEligibilitiesResponseV1;
  preparedRefundInfo: PreparedRefundInfo | null;
  disruptionFlightRefundResult: DisruptionFlightRefundResult | null;
  fetchDisruptionPreparedFlightRefundCallState: CallState;
  submitDisruptionFlightRefundCallState: CallState;
  // note: productRedeemChoice is added for event tracking; it's specifically used in refund related pages
  productRedeemChoice: "missed_connection" | "delay" | null;
  sliceIndex: number | null;
}

const initialState: IDisruptionProtectionState = {
  selectedDisruptionProtectionItineraryId: null,
  fetchFlightDisruptionsCallState: CallState.NotCalled,
  flightDisruptions: { eligibilityByItinerary: {} },
  preparedRefundInfo: null,
  disruptionFlightRefundResult: null,
  fetchDisruptionPreparedFlightRefundCallState: CallState.NotCalled,
  submitDisruptionFlightRefundCallState: CallState.NotCalled,
  productRedeemChoice: null,
  experiments: null,
  sliceIndex: null,
};

export interface IDisruptionProtectionExperiments {
  [ActiveExperiments.DisruptionProtection24hRule]: DefaultVariantType;
  [ActiveExperiments.RAPID_REBOOK_RENAME]: DefaultVariantType;
  [ActiveExperiments.FINTECH_CSAT]: DefaultVariantType;
  [ActiveExperiments.POST_BOOKING_OFFER_MY_TRIPS]: DefaultVariantType;
}

export const getExperimentState = (
  state: IDisruptionProtectionState | undefined
): IDisruptionProtectionExperimentState => {
  return {
    experiments: state?.experiments ?? initialState.experiments,
  };
};

export function reducer(
  state: IDisruptionProtectionState = initialState,
  action: actions.DisruptionProtectionActions
): IDisruptionProtectionState {
  switch (action.type) {
    case actionTypes.SET_SELECTED_FLIGHT_DISRUPTION_PROTECTION_ITINERARY_ID:
      return {
        ...state,
        selectedDisruptionProtectionItineraryId: action.itineraryId,
      };

    case actionTypes.FETCH_FLIGHT_DISRUPTIONS:
      return {
        ...state,
        fetchFlightDisruptionsCallState: CallState.InProcess,
      };

    case actionTypes.SET_FETCH_FLIGHT_DISRUPTIONS_CALL_STATE_FAILED:
      return {
        ...state,
        flightDisruptions: initialState.flightDisruptions,
        fetchFlightDisruptionsCallState: CallState.Failed,
      };

    case actionTypes.SET_FLIGHT_DISRUPTIONS:
      return {
        ...state,
        flightDisruptions: action.flightDisruptions,
        fetchFlightDisruptionsCallState: CallState.Success,
      };

    case actionTypes.FETCH_DISRUPTION_PREPARED_FLIGHT_REFUND:
      const {} = action;
      return {
        ...state,
        fetchDisruptionPreparedFlightRefundCallState: CallState.InProcess,
      };

    case actionTypes.SET_PREPARED_REFUND_INFO:
      const { preparedRefundInfo } = action;
      return { ...state, preparedRefundInfo };

    case actionTypes.SET_FETCH_FLIGHT_DISRUPTION_PREPARED_FLIGHT_REFUND_CALL_STATE:
      return {
        ...state,
        fetchDisruptionPreparedFlightRefundCallState: action.callState,
      };

    case actionTypes.SUBMIT_DISRUPTION_FLIGHT_REFUND:
      return {
        ...state,
        submitDisruptionFlightRefundCallState: CallState.InProcess,
      };

    case actionTypes.SET_DISRUPTION_FLIGHT_REFUND_RESULT:
      return {
        ...state,
        disruptionFlightRefundResult: action.result,
      };

    case actionTypes.SET_SUBMIT_DISRUPTION_FLIGHT_REFUND_CALL_STATE:
      return {
        ...state,
        submitDisruptionFlightRefundCallState: action.callState,
      };

    case actionTypes.SET_PRODUCT_REDEEM_CHOICE:
      return {
        ...state,
        productRedeemChoice: action.choice,
      };

    case actionTypes.SET_DISRUPTION_PROTECTION_EXPERIMENTS:
      return {
        ...state,
        experiments: action.experiments,
      };

    case actionTypes.SET_SLICE_SELECTED_FOR_MCP_VI:
      return {
        ...state,
        sliceIndex: action.sliceIndex,
      };

    default:
      return state;
  }
}

export * from "./selectors";
