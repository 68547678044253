import React, { useEffect, useContext } from "react";
import { RouteComponentProps, Route } from "react-router-dom";
import { Box, Typography } from "@material-ui/core";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  CallState,
  DISRUPTION_ITINERARY_ID_QUERY_PARAM,
  FlightItineraryState,
  GetExerciseEligibilitiesRequestV1ByItineraryIds,
  GetExerciseEligibilitiesRequestV1Enum,
} from "redmond";
import { LoadingPopup, B2BSpinner, ButtonWrap, useDeviceTypes } from "halifax";
import clsx from "clsx";
import dayjs from "dayjs";

import { DisruptionProtectionConnectorProps } from "./container";
import {
  PORTAL_TITLE,
  DISRUPTION_PROTECTION_TITLE_NEW,
  DISRUPTION_PROTECTION_TITLE_OLD,
  MCP_TITLE,
} from "../../lang/textConstants";
import {
  BASE_PATH_HOME,
  PATH_DISRUPTION_PROTECTION_OVERVIEW,
  PATH_DISRUPTION_PROTECTION_REFUND,
  PATH_DISRUPTION_PROTECTION_REBOOK_FLIGHT,
  PATH_DISRUPTION_PROTECTION_MCP_REBOOK_CONNECTING_FLIGHT,
  PATH_DISRUPTION_PROTECTION_REBOOK_CONNECTION,
} from "../../utils/paths";
import {
  pushToDisruptionOverview,
  pushToTripsHome,
} from "../../utils/queryStringHelpers";
import {
  DisruptionProtectionBanner,
  DisruptionProtectionLanding,
  DisruptionProtectionFooter,
  DisruptionProtectionRebook,
} from "./components";
import { MyTripsHeader } from "../common";
import * as constants from "./constants";
import "./styles.scss";
import { DisruptionProtectionRefund } from "./components/DisruptionProtectionRefund";
import { ClientContext } from "../../App";
import { MissedConnectionProtectionLanding } from "./components/MissedConnectionProtectionLanding";

export interface IDisruptionProtectionProps
  extends DisruptionProtectionConnectorProps,
    RouteComponentProps {}

export const DisruptionProtection = ({
  history,
  areAllFlightFetchingInTerminalState,
  areAllFlightFetchingNotCalled,
  disruptionProtectionItinerary,
  fetchFlights,
  setSelectedFlightDisruptionProtectionItineraryId,
  selectedFlightDisruptionProtectionItineraryId,
  fetchDisruptionPreparedFlightRefund,
  fetchFlightDisruptions,
  areSomeDisruptionInfoFetchingsInProcess,
  setDisruptionFlightRefundResult,
  setFetchDisruptionPreparedFlightRefundCallState,
  setPreparedRefundInfo,
  setSubmitDisruptionFlightRefundCallState,
  isDisruptionProtection24hRuleSatisfied,
  eligibleSlice,
  fetchDisruptionPreparedFlightRefundCallState,
  isRapidRebookRenameEnabled,
  isPostBookingOfferMyTripsEnabled,
}: IDisruptionProtectionProps) => {
  const { matchesMobile } = useDeviceTypes();
  const { isAgentPortal } = useContext(ClientContext);
  const [openContactModal, setOpenContactModal] =
    React.useState<boolean>(false);
  const isMissedConnectionProtecion =
    history.location.pathname ===
    PATH_DISRUPTION_PROTECTION_MCP_REBOOK_CONNECTING_FLIGHT;

  // Virtual Interline Flights will offer only one type of fare the most basic is safe to only check the first index
  const isVITripSelected =
    disruptionProtectionItinerary?.bookedItinerary.travelItinerary.slices.some(
      (slice) => slice.segments.some((segment) => segment.isSelfTransferLayover)
    );

  useEffect(() => {
    window.scrollTo(0, 0);
    // note: it sets document title
    document.title = isMissedConnectionProtecion
      ? MCP_TITLE
      : isRapidRebookRenameEnabled
      ? DISRUPTION_PROTECTION_TITLE_NEW
      : DISRUPTION_PROTECTION_TITLE_OLD;

    setSelectedFlightDisruptionProtectionItineraryId(null);

    // note: it sets the selected disruption-protection itinerary id
    const urlSearchParams = new URLSearchParams(history.location.search);
    const disruptionProtectionItineraryIdFromQuery = urlSearchParams.get(
      DISRUPTION_ITINERARY_ID_QUERY_PARAM
    );
    if (disruptionProtectionItineraryIdFromQuery) {
      setSelectedFlightDisruptionProtectionItineraryId(
        disruptionProtectionItineraryIdFromQuery
      );
    }

    return () => {
      document.title = PORTAL_TITLE;
    };
  }, []);

  useEffect(() => {
    if (selectedFlightDisruptionProtectionItineraryId) {
      setDisruptionFlightRefundResult(null);
      setSubmitDisruptionFlightRefundCallState(CallState.NotCalled);
    }
  }, [selectedFlightDisruptionProtectionItineraryId]);

  useEffect(() => {
    if (selectedFlightDisruptionProtectionItineraryId) {
      fetchFlightDisruptions({
        itineraryIds: [selectedFlightDisruptionProtectionItineraryId],
        GetExerciseEligibilitiesRequestV1:
          GetExerciseEligibilitiesRequestV1Enum.ByItineraryIds,
      } as GetExerciseEligibilitiesRequestV1ByItineraryIds);
    }
  }, [selectedFlightDisruptionProtectionItineraryId]);

  useEffect(() => {
    if (
      (eligibleSlice || isAgentPortal) &&
      selectedFlightDisruptionProtectionItineraryId &&
      fetchDisruptionPreparedFlightRefundCallState !== CallState.InProcess
    ) {
      setPreparedRefundInfo(null);
      setFetchDisruptionPreparedFlightRefundCallState(CallState.NotCalled);
      fetchDisruptionPreparedFlightRefund({
        itineraryId: selectedFlightDisruptionProtectionItineraryId,
      });
    }
  }, [
    isAgentPortal,
    eligibleSlice,
    selectedFlightDisruptionProtectionItineraryId,
  ]);

  useEffect(() => {
    if (areAllFlightFetchingNotCalled) {
      /*
        TODO: it fetches all flight itineraries - it should be replaced with an endpoint that
        returns a single itinerary (specified by the itinerary id) only.
      */
      fetchFlights(
        {
          states: [FlightItineraryState.Future, FlightItineraryState.Present],
          referenceDateTime: dayjs().format(),
        },
        history,
        isPostBookingOfferMyTripsEnabled
      );
      fetchFlights(
        {
          states: [FlightItineraryState.Past, FlightItineraryState.Canceled],
          referenceDateTime: dayjs().format(),
        },
        history
      );
    }
  }, [areAllFlightFetchingNotCalled]);

  useEffect(() => {
    if (
      areAllFlightFetchingInTerminalState &&
      !selectedFlightDisruptionProtectionItineraryId &&
      (!disruptionProtectionItinerary ||
        (!disruptionProtectionItinerary.ancillaries.delay &&
          !disruptionProtectionItinerary.ancillaries.missedConnection))
    ) {
      pushToTripsHome({ history });
    }
  }, [
    selectedFlightDisruptionProtectionItineraryId,
    disruptionProtectionItinerary,
    areAllFlightFetchingInTerminalState,
  ]);

  // note: gating logic added for the 24-hour rule; it's not applied to agents
  useEffect(() => {
    if (
      !isAgentPortal &&
      !isDisruptionProtection24hRuleSatisfied(
        disruptionProtectionItinerary?.departureTime
      )
    ) {
      pushToTripsHome({ history });
    }
  }, [isDisruptionProtection24hRuleSatisfied, disruptionProtectionItinerary]);

  const disruptionNavigatorCopy: {
    title: string;
    subtitle?: string;
  } = (() => {
    switch (history.location.pathname) {
      case PATH_DISRUPTION_PROTECTION_OVERVIEW:
        if (matchesMobile) {
          return {
            title: isRapidRebookRenameEnabled
              ? constants.DISRUPTION_PROTECTION_NEW
              : constants.DISRUPTION_PROTECTION_OLD,
            subtitle: constants.GET_HELP_WITH_TRIP,
          };
        } else {
          return {
            title: constants.BACK_TO_MY_TRIPS,
          };
        }
      case PATH_DISRUPTION_PROTECTION_REFUND:
      case PATH_DISRUPTION_PROTECTION_REBOOK_FLIGHT:
      case PATH_DISRUPTION_PROTECTION_REBOOK_CONNECTION:
        if (matchesMobile) {
          return {
            title: isRapidRebookRenameEnabled
              ? constants.DISRUPTION_PROTECTION_NEW
              : constants.DISRUPTION_PROTECTION_OLD,
            subtitle: constants.GET_HELP_WITH_TRIP,
          };
        } else {
          return {
            title: constants.GO_BACK,
          };
        }
      case PATH_DISRUPTION_PROTECTION_MCP_REBOOK_CONNECTING_FLIGHT: {
        if (matchesMobile) {
          return {
            title: isVITripSelected
              ? constants.MCR_HEADER_TITLE
              : constants.MCP_HEADER_TITLE,
            subtitle: constants.GET_HELP_WITH_TRIP,
          };
        } else {
          return {
            title: constants.BACK_TO_MY_TRIPS,
          };
        }
      }
      default:
        return {
          title: constants.BACK_TO_MY_TRIPS,
        };
    }
  })();

  const handleGoBack = () => {
    switch (history.location.pathname) {
      case PATH_DISRUPTION_PROTECTION_OVERVIEW:
      case PATH_DISRUPTION_PROTECTION_MCP_REBOOK_CONNECTING_FLIGHT:
        pushToTripsHome({
          history,
          itineraryId: disruptionProtectionItinerary?.bookedItinerary.id,
        });
        break;
      case PATH_DISRUPTION_PROTECTION_REFUND:
      case PATH_DISRUPTION_PROTECTION_REBOOK_FLIGHT:
      case PATH_DISRUPTION_PROTECTION_REBOOK_CONNECTION:
        pushToDisruptionOverview({
          history,
          itineraryId: disruptionProtectionItinerary?.bookedItinerary.id,
        });
        break;
      default:
        break;
    }
  };

  const DisruptionProtectionBody = (
    <Box className="disruption-protection-body">
      {matchesMobile && (
        <MobileNavigator {...disruptionNavigatorCopy} onClick={handleGoBack} />
      )}
      {/* note: disruption protection banner is displayed on the landing page only */}
      <Route
        path={[
          PATH_DISRUPTION_PROTECTION_OVERVIEW,
          PATH_DISRUPTION_PROTECTION_MCP_REBOOK_CONNECTING_FLIGHT,
        ]}
        exact
      >
        <DisruptionProtectionBanner
          isMissedConnectionProtecion={isMissedConnectionProtecion}
          isRapidRebookRenameEnabled={isRapidRebookRenameEnabled}
        />
      </Route>
      {!matchesMobile && (
        <Box className="go-back-button-wrapper">
          <GoBackButton {...disruptionNavigatorCopy} onClick={handleGoBack} />
        </Box>
      )}
      <Route path={[PATH_DISRUPTION_PROTECTION_OVERVIEW]} exact>
        <DisruptionProtectionLanding />
      </Route>
      <Route path={[PATH_DISRUPTION_PROTECTION_REFUND]} exact>
        <DisruptionProtectionRefund />
      </Route>
      <Route
        path={PATH_DISRUPTION_PROTECTION_MCP_REBOOK_CONNECTING_FLIGHT}
        exact
      >
        <MissedConnectionProtectionLanding isAgentPortal={isAgentPortal} />
      </Route>
      <Route
        path={[
          PATH_DISRUPTION_PROTECTION_REBOOK_FLIGHT,
          PATH_DISRUPTION_PROTECTION_REBOOK_CONNECTION,
        ]}
        exact
      >
        <DisruptionProtectionRebook isMobile={matchesMobile} />
      </Route>
    </Box>
  );

  return (
    <Box
      className={clsx("disruption-protection-root", { mobile: matchesMobile })}
    >
      <Box className="disruption-protection-container">
        <LoadingPopup
          open={areSomeDisruptionInfoFetchingsInProcess}
          indicator={B2BSpinner}
          message={constants.LOADING_FLIGHT_DETAILS}
          classes={(() => {
            const classes = ["disruption-protection-loading-modal"];
            if (matchesMobile) classes.push("mobile");
            return classes;
          })()}
        />
        <MyTripsHeader
          className="disruption-protection-trips-header"
          onClick={() => history.push(BASE_PATH_HOME)}
          tripHeaderCopy={{
            title: isMissedConnectionProtecion
              ? isVITripSelected
                ? constants.MCR_HEADER_TITLE
                : constants.MCP_HEADER_TITLE
              : isRapidRebookRenameEnabled
              ? constants.HEADER_TITLE_NEW
              : constants.HEADER_TITLE_OLD,
            subtitle: isMissedConnectionProtecion
              ? constants.MCP_HEADER_SUBTITLE
              : constants.HEADER_SUBTITLE,
          }}
          hidden={matchesMobile}
          contactModalController={{
            openContactModal,
            setOpenContactModal,
          }}
        />
        {DisruptionProtectionBody}
        {/* note: disruption protection footer is displayed on the landing page only */}
        <Route
          path={[
            PATH_DISRUPTION_PROTECTION_OVERVIEW,
            PATH_DISRUPTION_PROTECTION_MCP_REBOOK_CONNECTING_FLIGHT,
          ]}
          exact
        >
          <DisruptionProtectionFooter
            isMissedConnectionProtecion={isMissedConnectionProtecion}
            isRapidRebookRenameEnabled={isRapidRebookRenameEnabled}
          />
        </Route>
      </Box>
    </Box>
  );
};

interface IMobileNavigatorProps {
  title: string;
  subtitle?: string;
  onClick: () => void;
}

export const MobileNavigator = ({
  title,
  subtitle,
  onClick,
}: IMobileNavigatorProps) => {
  return (
    <Box className="mobile-navigator-root">
      <FontAwesomeIcon
        className="go-back-button"
        icon={faChevronLeft}
        onClick={onClick}
      />
      <Box className="info-section">
        <Typography className="title-copy" variant="subtitle1">
          {title}
        </Typography>
        {subtitle && (
          <Typography className="subtitle-copy" variant="caption">
            {subtitle}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

interface IGoBackButtonProps {
  title: string;
  onClick: () => void;
}

export const GoBackButton = ({ title, onClick }: IGoBackButtonProps) => {
  return (
    <ButtonWrap className="go-back-button-root" onClick={onClick}>
      <FontAwesomeIcon className="go-back-icon" icon={faChevronLeft} />
      <Typography className="button-copy" variant="subtitle1">
        {title}
      </Typography>
    </ButtonWrap>
  );
};
